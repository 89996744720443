// ThemeContext.js
import React, { createContext, useContext, useState } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    setIsDarkMode((prevMode) => !prevMode);
  };

  const toggleSidebar = () => { 
    /*const x=sidebarOpen 
    setSidebarOpen(_flag);
    console.log('side bar hello')
    console.log(_flag)*/
    setSidebarOpen((prevMode) => !prevMode);
    console.log('side bar hello')
    console.log(sidebarOpen)
  };

  return (
    <ThemeContext.Provider value={{ theme, isDarkMode, toggleTheme, sidebarOpen, toggleSidebar:(e)=>toggleSidebar(e) }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export { ThemeProvider, useTheme };

// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <div className="p-6 pt-0 mt-auto text-center dark:text-white-dark ltr:sm:text-left rtl:sm:text-right">© {new Date().getFullYear()}. TCB All rights reserved.</div>
  );
};

export default Footer;

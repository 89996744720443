import { graphqlOperation } from "../utils/crud"

export const AddSubscriber=async(email)=>{
    const mutation=`mutation MyMutation {
        addSubscriber(input: {email: \"${email}\"}) {
          ... on Subscriber {
            __typename
            created_at
            email
            updated_at
          }
          ... on CustomError {
            error
            message
            code
          }
        }
      }`

      const graphqlResponse=await graphqlOperation(mutation)
      return graphqlResponse
}
          
          
         
// Header.js
import React from 'react';
import { useEffect, useState,Fragment } from 'react';

import { Link, NavLink, useLocation,useNavigate } from 'react-router-dom';

import Dropdown from '../Dropdown';
import { Dialog, Transition } from '@headlessui/react';
import { useTheme } from '../../context/ThemeContext';
//import { useAuth } from '../../context/AuthContext';
import { AddSubscriber } from '../../graphql/subscriberGraphql';
//import axios from 'axios';


const Header = () => {
    //const {useremail } = useAuth();
    const { theme, isDarkMode, toggleTheme, sidebarOpen, toggleSidebar } = useTheme();
    const location = useLocation();
    const navigate=useNavigate()
    const [isRtl,setIsRtl]=useState(true)
   
    const [modal9, setModal9] = useState(false);
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [useremail,setUserEmail]=useState();

    const handleSubscriber = async (e) => {    
        e.preventDefault();
        setError('');
        setSuccess('')
        setIsLoading(true)
        try { 
          
                    
            const resp = await AddSubscriber(email)           
            //const resp=(graphqlResponse as any)
            console.log("Graph ql rESPONSE=")
            if(resp?.data?.addSubscriber.email==email) {
              setSuccess('Thanks for Subscribing our mailing list')
            }else if(resp?.data?.addSubscriber.error){
              setError("The email is already subscribed for update") 
            }
            
            setIsLoading(false)
           
         
        } catch (err) {
            console.log("mailchip error="+ err)
            setError('Subscription failed. Please try again later.');
            setIsLoading(false)
        }finally{
           
        }
      };


    const handleClear =() =>{
        setSuccess('')
        setModal9(true)
        setEmail('')
        setIsLoading(false)
        setError('')
        
      }
      useEffect(()=>{
        setUserEmail(localStorage.getItem("email"))
        //alert(useremail)
      },[])

  return (
    <header className={`z-40`}>
    <div className="shadow-sm">
        <div className="relative  bg-[#0081C3] flex w-full items-center px-5 py-2.5 dark:bg-black">
             {!sidebarOpen && 
            <div className="horizontal-logo flex  justify-between items-center mr-2 rtl:ml-2">
                <Link to="/" className="main-logo flex items-center shrink-0">
                <img className="mx-auto w-full h-auto" src="/assets/images/TCB_White_Logo_Email_signature.png" alt="logo" />
                    
                </Link>
                <button
                    type="button"
                    className="collapse-icon flex-none dark:text-[#0081C3] hover:text-primary dark:hover:text-primary flex  ml-2 rtl:mr-2 p-2 rounded-full bg-white-light/40 dark:bg-dark/40 hover:bg-white-light/90 dark:hover:bg-dark/60"
                    onClick={() => {
                       toggleSidebar();
                    }}
                >
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 7L4 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                        <path opacity="0.5" d="M20 12L4 12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M20 17L4 17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    </svg>
                </button>
            </div>
            }

          
            <div className="sm:flex-1 sm:ml-0 ml-auto sm:rtl:mr-0 rtl:mr-auto flex items-center space-x-1.5 lg:space-x-2 rtl:space-x-reverse dark:text-[#d0d2d6]">
                <div className="sm:mr-auto sm:rtl:ml-auto">
                   
                  
                </div>
                <div>
                    
                   
                </div>
               
                <div ><button  onClick={handleClear}  className='btn border-white mx-auto py-1 w-full max-w-md h-auto  btn-primary hover:bg-sky-700 text-white  text-[11px]'>Subscribe</button></div>
                <div ><button  onClick={e=>navigate('/release-notes')} className='btn border-white mx-auto w-full h-auto py-1  btn-primary hover:bg-sky-700 text-white text-[11px]'>Release Note</button></div>                                              
                {useremail &&
               <div className="dropdown shrink-0">
               <Dropdown
                       offset={[0, 8]}
                      
                       btnClassName="relative group block"
                       button={<img className="h-7 w-7 rounded-full object-cover saturate-50 group-hover:saturate-100" src="/assets/images/power.svg" alt="userProfile" />}
                   >
                       <ul className="w-[230px] mt-10 !py-0 font-semibold text-dark dark:text-white-dark dark:text-white-light/90">
                           <li>
                               <div className="flex items-center px-4 py-4">
                                
                               </div>
                           </li>
                           <li>
                               <Link to="#" className="dark:hover:text-white">
                                   <svg className="mr-2 rtl:ml-2 shrink-0" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <circle cx="12" cy="6" r="4" stroke="currentColor" strokeWidth="1.5" />
                                       <path
                                           opacity="0.5"
                                           d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
                                           stroke="currentColor"
                                           strokeWidth="1.5"
                                       />
                                   </svg>
                                   {useremail}
                               </Link>
                           </li>                                  
                          
                           <li className="border-t border-white-light dark:border-white-light/10">
                               <Link  to={{ pathname: '/auth/logout'}}  className="!py-3 text-danger">
                                   <svg className="rotate-90 mr-2 rtl:ml-2 shrink-0" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <path
                                           opacity="0.5"
                                           d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195"
                                           stroke="currentColor"
                                           strokeWidth="1.5"
                                           strokeLinecap="round"
                                       />
                                       <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                   </svg>
                                   Sign Out
                               </Link>
                           </li>
                       </ul>
                   </Dropdown>
               </div>
               }
               
            </div>
        </div>

        
    </div>

    <div  className="flex flex-wrap items-center justify-center gap-2 mb-5">
    
    <Transition appear show={modal9} as={Fragment}>
        <Dialog as="div" open={modal9} onClose={() => setModal9(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0" />
            </Transition.Child>
            <div id="fadein_modal" className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                <div className="flex items-start justify-center min-h-screen px-4">
                    <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-2xl my-8 text-black dark:text-white-dark animate__animated animate__fadeIn">
                        <div className="flex bg-primary dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                            <h5 className="font-bold text-lg text-white   text-xm">Enter your email to subscribe to the latest updates</h5>
                            <button onClick={() => {setModal9(false)}} type="button" className="text-white font-bold hover:text-dark">
                                X
                            </button>
                        </div>
                        <div className="p-5 f text-xm content-center">
                        <form className='text-center flex-1' onSubmit={handleSubscriber}>
                            <label className='text-slate-700 text-bold'></label>
                            <input 
                            placeholder='Input Email Address'
                            type="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}                                   
                            className="form-input" 
                            required />
                                                              
                            <button type="submit" className="btn w-auto btn-primary mt-4  flex-1 text-xm ">
                                Subscribe
                            </button>
                            
                        </form>
                        
                        {success && <div className='text-center font-bold mt-0 text-green-600 text-[18px]'><p className="success">{success}</p></div>}
                            {error && <div className='text-center font-bold mt-0 text-green-600 text-[18px]'><p className="error">{error}</p></div>}
                            {isLoading &&
                            <div className="flex justify-center items-center">
                                {/*<button onClick={() => setModal9(false)} type="button" className="btn btn-outline-danger ">
                                    Close
</button>*/}                     <img className="h-8 max-w-xs" src="/assets/images/Iphone-spinner-2.gif" alt="logo" />
                                
                            </div>
                            }
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    </Transition>
    </div>

</header>
  );
};

export default Header;

import {  Auth } from '@aws-amplify/auth';
import  {REGION,USER_POOL_ID,USER_POOL_APP_CLIENT_ID,AWS_APPSYNC_GRAPHQULENDPOINT}  from './aws-exports';
import OAuth from '@aws-amplify/auth/lib/OAuth/OAuth'
import { API, Amplify } from 'aws-amplify';
import { json } from 'react-router-dom';

let globalUser=null


export const configureCognito=async ()=>{ 
  const awtToken=localStorage.getItem("Authorization")  
 
  Amplify.configure({
      Auth: {
        region:process.env.REACT_APP_REGION,
        userPoolId:process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId:process.env.REACT_APP_USER_POOL_APP_CLIENT_ID      
      },
      API:{
        graphql_endpoint:process.env.REACT_APP_AWS_APPSYNC_GRAPHQULENDPOINT,
        graphql_headers : async () =>({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${awtToken}`, 
    
        })        
      }            
    });
} 


  
  export const getAuthentication=async (jsonData)=>{
    configureCognito()
    const resp=await Auth.signIn(jsonData.email, jsonData.password);
    globalUser=resp      
    return resp
}
export const LogOut= async ()=>{
  configureCognito()
  await Auth.signOut();
}

export const IsLoggedIn=async()=>{
  try{

    configureCognito()
    const userPool= await Auth.currentUserPoolUser()
    console.log("*******TOKEN******")
    console.log(userPool)
    return userPool
  }catch(err){
    return err
  }

}

export const GraphQlHeaders = async (graphqml) => {
  configureCognito()
  try{
 
    //console.log(awtToken)
    const headers='da2-umfdxuexlfbmnfcv57t7hvgvsy'

    Amplify.configure({
      API:{
      graphql_endpoint:AWS_APPSYNC_GRAPHQULENDPOINT,
      graphql_headers : async () =>({
        'Content-Type': 'application/json',
        'x-api-key': `${headers}`, 
  
      })        
    }})   
   
    const graphqlResponse = await API.graphql({
      query: graphqml,
      authMode: 'AMAZON_COGNITO_USER_POOLS',
      variables: { limit: 10 }, 
     
    });
      console.log("graphql response")
      console.log(graphqlResponse)
      return graphqlResponse;

    }catch(err){
      console.log("graphql error=")
      console.log(err)

      return null

    }
}

export const graphqlOperation = async (item) => {
  configureCognito()
    try{
      const awtToken= (await Auth.currentSession()).getAccessToken().getJwtToken() 
      console.log("*******TOKEN******")
      console.log(awtToken)
      Amplify.configure({
         API:{
         graphql_endpoint:AWS_APPSYNC_GRAPHQULENDPOINT,
         graphql_headers : async () =>({
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${awtToken}`, 
     
         })        
       }})    
     console.log(awtToken)
     const graphqlResponse = await API.graphql({
       query: item,
       authMode: 'AMAZON_COGNITO_USER_POOLS',
       variables: { limit: 10 }, 
      
     });
     return graphqlResponse

    }catch(err){
      console.log(err)
    }
   
  };



  const refreshToken=async()=>{
    try {
      configureCognito();
      const currentUser = await Auth.currentAuthenticatedUser(); 
      const currentSession = await Auth.currentSession();
      currentUser.refreshSession(currentSession.refreshToken, (err, session) => { 
      console.log("Refresh Session")
      console.log(session)
      localStorage.setItem("Authorization",session.accessToken.jwtToken)   
      localStorage.setItem("isAuthenticated",true)  
        return true;
      });
    } catch (refreshError) {
      console.error('Error regenerating token:', refreshError);
      localStorage.setItem("isAuthenticated",false)  
      return false;
      
    }
  
  }
    export const isValidToken=()=>{  
      try{
        Auth.currentSession()
        .then(data => {  
          return true  
        })
        .catch(err => {      
          return refreshToken()        
        });
      }catch(err){      
        console.log(err)
        localStorage.setItem("isAuthenticated",false)  
        return false
        
      }
   
    }
  
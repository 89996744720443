 /*export const awsExports = {
    "REGION" : "eu-north-1",
    "USER_POOL_ID": "eu-north-1_1jn2FChnw",
    "USER_POOL_APP_CLIENT_ID": "5rr2vsp1pnqhj4j81cu8ktaded"
}*/

/*export const REGION = 'eu-north-1';
export const USER_POOL_ID = 'eu-north-1_1jn2FChnw';
export const USER_POOL_APP_CLIENT_ID = '5rr2vsp1pnqhj4j81cu8ktaded';*/



export const REGION = "us-east-1";
export const USER_POOL_ID= "us-east-1_uKkSdzlMg";
export const USER_POOL_APP_CLIENT_ID= "5vm1kl56bru1otn0ukjtltinc6";
export const AWS_APPSYNC_GRAPHQULENDPOINT="https://dev.superadmin.tcbapi.org/graphql";
import { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink, useLocation,Link,useNavigate } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { useTheme } from '../../context/ThemeContext';
const Sidebar = () => {
    const { theme, isDarkMode, toggleTheme, sidebarOpen, toggleSidebar } = useTheme();
    const [currentMenu, setCurrentMenu] = useState('');
    const [errorSubMenu, setErrorSubMenu] = useState(false);
    
    const location = useLocation();    

    const [menuid, setid] = useState(0);
    const navigate=useNavigate()


    const toggleMenu = (value) => {
        let num=value +".1"
        setid(parseFloat(num))        
        //router.push('/developer/api-docs?id=' + value + ".1")
        setCurrentMenu((oldValue) => {
            return oldValue === value ? '' : value;
        });
    };
   
    const [user,setUser]=useState('')

    useEffect(()=>{
        //const url = process.env.REACT_APP_API_BASEURL + process.env.REACT_APP_API_MENUURL;
        
        const url="https://thecouponbureau-dev-apidoc-storage-bucket.s3.amazonaws.com/content/content/public/menu/menu.json";
       // alert( process.env.REACT_APP_API_DOC_URL)
        const fetchData = async () => {
          try {
            const response = await fetch(url);
            const json = await response.json();
            console.log(json);
            setCustomMenu(json)
            localStorage.setItem("localMenu",JSON.stringify(json))    
            console.log("Final menu json=" + customMenu)
          } catch (error) {
            console.log("error", error);
          }
        };
    
      fetchData();
    },[])
    useEffect(() => {
        console.log("hooks")
        const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const ul = selector.closest('ul.sub-menu');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }

       
    }, []);

    useEffect(() => {
       // toggleSidebar(true);
      
        if (window.innerWidth < 1024 && sidebarOpen) {
            toggleSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    
    const [active, setActive] = useState(false);
    const [customMenu,setCustomMenu]=useState('')
    const handleClick = (e ) => {            
        setActive(!active);       
        setid(e)
        navigate(`/developer/api-docs?id=${e}`)
       
    };


 

    return (

        <> {sidebarOpen && 
        <div className='transition-all duration-300'>
          
           
        <nav
            className={`sidebar bg-white text-[13px] transition-all duration-300 fixed top-0 bottom-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300`}
        >
            <div className="h-full  dark:bg-black ">
            
                <div className="flex h-[50px] items-center bg-[#0081C3] justify-between px-3 py-3">
                    <Link to="/" className="main-logo flex shrink-0 items-center">
                        
                    <img className="h-6 max-w-xs" src="/assets/images/TCB_White_Logo_Lowres_Wide.png" alt="logo" />
                        {/*<span className="align-middle text-2xl font-semibold ml-1.5 rtl:mr-1.5 dark:text-white-light lg:inline">{t('The Coupon Bureau')}</span>*/}
                    </Link>


                    <button
                        type="button"
                        className="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10"
                       onClick={() => toggleSidebar()}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="m-auto h-5 w-5">
                            <path d="M13 19L7 12L13 5" stroke="white" strokeWidth="2.0" strokeLinecap="round" strokeLinejoin="round" />
                            <path opacity="0.5" d="M16.9998 19L10.9998 12L16.9998 5" stroke="white" strokeWidth="2.0" strokeLinecap="round" strokeLinejoin="round" />
                            
                        </svg>
                    </button>
                </div>
                <PerfectScrollbar options={{ suppressScrollX: true }}  className="relative mt-2 h-[calc(100vh-90px)] ">
                {/*<p className='text-left '>{user ?<button onClick={e=>navigate('/menu-editor')}><img className="h-6 max-w-xs" src="/assets/images/note-edit-outline.svg" alt="logo" /></button> :null}</p>*/}
                    <ul className="relative space-y-0.5 p-1 py-0 font-semibold">
                        <li className="menu nav-item ">
                        {user ?<Link to="/menu-editor" className={`${
                                            menuid ==1 ? ' bg-sky-200 dark:text-primary' : ''
                                        }`}  onClick={() => {                                               
                                            handleClick(1);
                                            
                                        }}>
                                        <div className="flex items-center">
                                        <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        >
                                        <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                                        <line x1="8" y1="2" x2="8" y2="22"></line>
                                        <line x1="16" y1="2" x2="16" y2="22"></line>
                                        <line x1="4" y1="8" x2="20" y2="8"></line>
                                        <line x1="4" y1="16" x2="20" y2="16"></line>
                                        </svg>

                                            <span className="text-black pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Edit Menu</span>
                                        </div>
                                    </Link>:''
                                }
                        <Link to="/" className={`${
                                            menuid ==2 ? ' bg-sky-200 dark:text-primary' : ''
                                        }`}  onClick={() => {                                               
                                            handleClick(2);
                                            
                                        }}>
                                        <div className="flex items-center">
                                        <svg className="group-hover:!text-primary shrink-0" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            opacity="0.5"
                                            d="M2 12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274C22 8.77128 22 9.91549 22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M9 17.25C8.58579 17.25 8.25 17.5858 8.25 18C8.25 18.4142 8.58579 18.75 9 18.75H15C15.4142 18.75 15.75 18.4142 15.75 18C15.75 17.5858 15.4142 17.25 15 17.25H9Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                            <span className="text-black pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Getting Started</span>
                                        </div>
                                    </Link>
                            <Link to={{ pathname: '/get-accesstoken'}} className={`${
                                            menuid ==3 ? ' bg-sky-200 dark:text-primary' : ''
                                        }`}  onClick={() => {                                               
                                            handleClick(3);
                                            
                                        }} >
                                        <div className="flex items-center">
                                        <svg className="group-hover:!text-primary shrink-0" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            opacity="0.5"
                                            d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-5 7.723v2.277h-2v-2.277c-.595-.347-1-.984-1-1.723 0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723zm-5-7.723v-4c0-2.206 1.794-4 4-4 2.205 0 4 1.794 4 4v4h-8z"
                                            fill="currentColor"
                                        />
                                       
                                    </svg>
                                            <span className="text-black pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Access Token</span>
                                        </div>
                                    </Link>
                            
                        </li>

                        <h2 className="-mx-4 mb-1 flex items-center py-3 px-7 font-extrabold dark:bg-dark dark:bg-opacity-[0.08]">
                            <svg className="hidden h-5 w-4 flex-none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            <span>APIs</span>
                        </h2>
                    
                        {customMenu ? (   
                        <li className="nav-item">
                            <ul>                             
                            <div className="h-full bg-transparent flex-1 overflow-x-hidden p-0">
                          
                                {JSON.parse(JSON.stringify(customMenu)).map((data) => (                                    
                                   
                                    <li className="menu nav-item ">                                             
                                        <button type="button" className={`${currentMenu === data.id ? 'active' : ''}  nav-link group w-full`} onClick={() => toggleMenu(data.id)}>
                                            <div className="flex items-left">                                                
                                                <span className="text-black  pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">{data.text}</span>
                                            </div>

                                            <div className={currentMenu === data.id ? '!rotate-90' : 'rtl:rotate-180'}>
                                                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 5L15 12L9 19" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </button>                                  
                                        

                                        <AnimateHeight duration={300} height={currentMenu === data.id ? 'auto' : 0}>
                                            <ul className="sub-menu text-gray-500">
                                            {data?.children?.map((children ) => ( 
                                           <li>
                                            <button className={ `text-justify ${
                                            menuid === children.id ? ' bg-sky-200 dark:text-primary' : ''
                                        }` }  onClick={() => {                                               
                                            handleClick(children.id);
                                            
                                            
                                        }}>{children?.text}</button>
                                                {/*<Link className={`${
                                            menuid === children.id ? ' bg-sky-200 dark:text-primary' : ''
                                        }`}  onClick={() => {                                               
                                            handleClick(children.id);
                                            
                                        }}  to={`/developer/api-docs?id=${children.id}`}  >{children.text}</Link>*/}
                                            </li>

                                            ))}  
                                                
                                               
                                            </ul>
                                        </AnimateHeight>
                                    </li>
                                   
                                           
                                 ))}   
                             
                            </div>                                 
                            
                      
                              


                            </ul>
                        </li>
                          ) : (
                            <div className="grid h-full min-h-[300px] place-content-center text-lg font-semibold">No data available</div>

                        )} 
               

                    </ul>
                </PerfectScrollbar>
            </div>
        </nav>
    </div>
    }</>
    );
};

export default Sidebar;

import React, { Suspense,lazy, useEffect } from 'react';


import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import LazyRoutes from './routes/LazyRoutes';
import NotFound from './components/NotFound';

import { useTheme } from '../src/context/ThemeContext';
import { useRoutes } from "react-router-dom";
import { useAuth } from '../src/context/AuthContext';


function App() {
  const { theme, isDarkMode, toggleTheme, sidebarOpen, toggleSidebar } = useTheme();
  const content = useRoutes(LazyRoutes);
  const {checkUser } = useAuth();

  useEffect(()=>{
    checkUser()
  })
  

  return (

   
  <Suspense>
    {content}
  </Suspense>

  );
}

export default App;

import React, { lazy } from 'react';
import Layout from '../components/Layouts/Layout';
const Index = lazy(() => import('../pages/Index'));
const GetStarted = lazy(() => import('../pages/GetStarted'));
const ReleaseNotes = lazy(() => import('../pages/ReleaseNotes'));
const AccessToken = lazy(() => import('../pages/AccessToken'));
const MenuEditor = lazy(() => import('../pages/MenuEditor'));
const EditReleaseNotes = lazy(() => import('../pages/EditReleaseNotes'));
const EditGetStarted = lazy(() => import('../pages/EditGetStarted'));
const EditApiHeading = lazy(() => import('../pages/EditApiHeading'));
const Docs = lazy(() => import('../pages/Docs'));
const Login = lazy(() => import('../pages/auth/Login'));
const Logout = lazy(() => import('../pages/auth/Logout'));
//import GetStarted from "../pages/GetStarted";
const LazyRoutes = [
  {
    path: "/",
    element:<Layout><Index /></Layout>
  },
  {
    path: "/get-started",
    element:<Layout><GetStarted /></Layout>
  },
  {
    path: "/release-notes",
    element:<Layout><ReleaseNotes /></Layout>
  },
  {
    path: "/get-accesstoken",
    element:<Layout><AccessToken /></Layout>
  },
  {
    path: "/apps/edit-getstarted",
    element:<Layout><EditGetStarted /></Layout>
  },
  {
    path: "/edit-releasenotes",
    element:<Layout><EditReleaseNotes /></Layout>
  },
  {
    path: "/edit-apiheading",
    element:<Layout><EditApiHeading /></Layout>
  },
  {
    path: "/menu-editor",
    element:<Layout><MenuEditor /></Layout>
  },
  {
    path: "/developer/api-docs",
    element:<Layout><Docs /></Layout>
  },
  {
    path: "/auth/login",
    element:<Login />
  },
  {
    path: "/auth/logout",
    element:<Logout />
  }
]
/*const LazyRoutes = [
  {
    path: "/",
    element:  <Layout/> ,
 
    children: [
      {
        path: "/",
        element: <Home />,
      },

      {
        path: "/get-started",
        element: <GetStarted />,
      },
    ],
  },
]*/


export default LazyRoutes;

import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { Auth } from 'aws-amplify';
import { configureCognito } from '../utils/crud';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [useremail, setEmail] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    configureCognito();
    checkUser(); // Check if the user is already authenticated on mount
  }, []);

  const checkUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      console.log("User Log***********")      
      console.log(authUser)
     //alert(authUser.attributes.email)
      //setUser(authUser) 
      if(authUser.attributes.email_verified){
       
        localStorage.setItem("Authorization",authUser.signInUserSession.idToken.jwtToken)   
        localStorage.setItem("email",authUser.attributes.email)
        localStorage.setItem("isAuthenticated",true)    
        //
        //alert(JSON.stringify(authUser.attributes))
      }else{
        alert('failed') 
        setIsAuthenticated(false);
      }
      //setUser({ username: authUser.username });
    } catch (error) {
      setUser(null);     
      localStorage.setItem("Authorization","")
      localStorage.setItem("isAuthenticated",false)  
      localStorage.setItem("email","");
      setIsAuthenticated(false);
      
    }
  };
  const LogIn = async (username, password) => {
    try { 
      const data  =await Auth.signIn(username, password);
      console.log("***********User Logged In****************")
      console.log(data)
      setEmail(data.attributes.email)
      checkUser();
      return data;
    } catch (error) {
      console.error('Error in Auth Context :', error);
      return error;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      checkUser();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const authContextValue = {
    user,
    useremail,
    isAuthenticated,
    LogIn,
    signOut,
    checkUser
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
